import { createActions, createReducer } from 'reduxsauce';
import _ from 'underscore';

import { SENTRY, cloneDeep, findReferrer } from 'components/modules/_misc';
import { set_identity, set_app_id } from 'components/modules/ada';
import {
	DEFAULT,
	updateScreensCompleted,
	calculateProgressbar,
	calculateResidencyStatus,
	translateOldAppJson,
	cloneApp,
	check_missing_data,
	has_fee
} from 'components/modules/app';
import { strNow } from 'components/modules/date';
import { OS, currentBrowser, userAgent } from 'components/modules/platform';
import { getIncompleteRoutes } from 'components/modules/nav';
import { DEFAULT_STATE } from 'components/modules/redux';
import { is_app_open } from 'components/modules/term';

let app_from_db;

const { Types, Creators } = createActions({
	finishedLoading: null,
	login: ['obj', 'direct_admit'],
	logout: null,
	setUserProperty: ['str_prop', 'val'],
	setApps: ['app_recs', 'new_cart'],
	setEmailAsVerified: null,
	setAppId: ['id', 'terms', 'for_pay'],
	setUseTempAppRec: ['use_temp_app_rec', 'unset_creating_additional_app'],
	createApp: ['cloned_from_id', 'term_bypassed', 'essay_requirement'],
	updateApp: ['obj', 'app_id'],
	saveApp: ['obj'],
	handleAppSave: ['strError', 'new_app_rec'],
	deleteApp: ['id'],
	setSubmenus: ['str', 'val'],
	setScreensCompleted: ['obj'],
	applyBypassObject: ['obj'],
	createAdditionalApp: null,
	addToCart: ['id'],
	submitLater: ['id'],
	setForcePwdReset: ['val'],
	setAppFees: ['obj'],
	getDocuments: ['arr'],
	addDocument: ['file_type', 'file_name', 'key'],
	redeemCoupon: ['app_id', 'coupon_id'],
	clearCoupon: ['app_id', 'app_fee'],
	setDirectAdmit: ['val'],
	setIpAddress: ['obj']
});

const finishedLoading = (state, action) => {
		let objState = cloneDeep(state);
		objState.loading_local_user = false;

		return objState;
	},
	login = (state, action) => {
		let objState = cloneDeep(state),
			{ obj, direct_admit } = action,
			{
				id,
				email,
				field_first_name,
				field_last_name,
				field_phone_number,
				field_no_phone,
				field_permission_to_send_texts,
				field_email_verified,
				field_verification_code_generate,
				code_generated,
				support_student_id,
				campus_admin
			} = obj;

		SENTRY.set_tag('email', email || '');
		set_identity(email, `${field_first_name} ${field_last_name}`);

		objState = {
			...objState,
			user_id: id || '',
			email: email || '',
			first_name: field_first_name || '',
			last_name: field_last_name || '',
			phone_number: field_phone_number || '',
			no_phone: field_no_phone === '1',
			permission_to_send_texts: field_permission_to_send_texts || '',
			support_student_id: support_student_id || '',
			campus_admin: campus_admin,
			email_verified: field_email_verified || '',
			verification_code_generated_date: field_verification_code_generate || '',
			verification_code_generated: !!code_generated
		};

		if (Object.keys(direct_admit || {}).length) objState.direct_admit = direct_admit;

		return objState;
	},
	logout = (state, action) => {
		let { ip_addr } = state,
			user = cloneDeep(DEFAULT_STATE.user);

		user.ip_addr = ip_addr;

		SENTRY.set_tag('email', '');
		set_identity('', '');

		localStorage.removeItem('user');

		return cloneDeep(user);
	},
	setApps = (state, action) => {
		let objState = cloneDeep(state),
			{ app_recs, new_cart } = action,
			had_apps = !!Object.keys(objState.apps).length,
			parsing_error = {
				app_id: '',
				message: ''
			};

		objState.apps = {};

		app_recs = app_recs.sort((a, b) =>
			a.date_last_updated < b.date_last_updated ? 1 : b.date_last_updated < a.date_last_updated ? -1 : 0
		);

		app_recs.forEach(rec => {
			let valid_json = true;

			try {
				rec.json_obj = JSON.parse(rec.application_json);

				const { screensCompleted, percentage_complete, application_modifier } = rec.json_obj;

				// tms:  valid DA forms can be less than 40%
				if (!screensCompleted.summaryCompleted && percentage_complete < 40 && application_modifier !== 'DA') {
					valid_json = false;
				}
			} catch (ex) {
				/*
					tms:
						don't pass exceptions up to global error-handling;
						it would block applicants from using the app until their invalid apps were deleted
				*/

				valid_json = false;

				// tms: the empty apps data bug seems to be all-or-nothing, so don't bother logging every error
				if (!parsing_error.app_id) {
					parsing_error = { app_id: rec.application_id, message: ex.message };
				}
			}

			if (valid_json) {
				delete rec.application_json;
				if (rec.date_submitted === '0000-00-00 00:00:00') rec.date_submitted = '';

				translateOldAppJson(rec.json_obj);
				check_missing_data(rec.json_obj);

				//collect platform info for support
				rec.json_obj.initial_information.platform_os = OS();
				rec.json_obj.initial_information.platform_browser = currentBrowser();
				rec.json_obj.initial_information.user_agent = userAgent();

				if (rec.json_obj.screensCompleted.personalInformationCompleted) {
					rec.json_obj.screensCompleted.basicInformationCompleted = true;
				}

				if (rec.date_submitted) rec.json_obj.screensCompleted.sign = true;

				objState.apps[rec.application_id] = rec;
			}
		});

		if (new_cart?.length) {
			objState.app_id = new_cart[0];
			objState.apps_in_cart = new_cart.join('|');
			objState.temp_app_rec = cloneDeep(objState.apps[new_cart[0]]);
		}

		// tms, 4/3/23: this can be removed if we identify the source of the empty apps data bug
		if (!Object.keys(objState.apps).length) {
			if (parsing_error.app_id) {
				SENTRY.set_tag('triggering_app_id', parsing_error.app_id);
				throw new Error(`setApps emptying apps data: ${parsing_error.message}`);
			} else if (had_apps) {
				throw new Error('setApps received an empty apps collection');
			}
		}

		return objState;
	},
	setEmailAsVerified = (state, action) => {
		let objState = cloneDeep(state);

		objState.email_verified = true;

		return objState;
	},
	setUserProperty = (state, action) => {
		let objState = cloneDeep(state),
			{ str_prop, val } = action;

		if (typeof objState[str_prop] !== 'undefined') objState[str_prop] = val;

		if (['email', 'first_name', 'last_name'].indexOf(str_prop) > -1) {
			set_identity(objState.email, `${objState.first_name} ${objState.last_name}`);
		}

		return objState;
	},
	setAppId = (state, action) => {
		let objState = cloneDeep(state),
			{ id, terms, for_pay } = action,
			add_to_cart = _app_id => {
				const _app = objState.apps[_app_id];

				if (_app_id === id || !is_app_open(_app.json_obj, terms)) return false;

				return for_pay
					? !!_app.date_submitted && has_fee(_app) && _app.paid === '0'
					: !_app.date_submitted &&
							!getIncompleteRoutes(_app.json_obj).length &&
							_app.app_type === objState.apps[id].app_type &&
							_app.term === objState.apps[id].term;
			};

		if (id !== objState.app_id) {
			objState.app_is_saving = false;
			objState.apps_in_cart = id || '';
			objState.apps_to_submit_later = '';

			if (id) {
				Object.keys(objState.apps)
					.filter(add_to_cart)
					.forEach(_app_id => {
						objState.apps_in_cart += `|${_app_id}`;
					});

				objState.apps_in_cart = objState.apps_in_cart.split('|').join('|');
			}

			objState.app_id = id || '';
			set_app_id(id || '');

			app_from_db = id ? objState.apps[id] : null; // save a copy to compare against when next trying to save; null is fine

			if (id) {
				const { referrer, apps } = objState,
					referrer_is_present = !!referrer._str || Object.keys(referrer).length > 1,
					referrer_already_captured = apps[id].json_obj.referrers.some(rec => findReferrer(rec, referrer));

				if (referrer_is_present && !referrer_already_captured) apps[id].json_obj.referrers.push(referrer);
			}
		}

		objState.temp_app_rec = cloneDeep(id ? objState.apps[id] : DEFAULT.APP_REC);

		return objState;
	},
	setUseTempAppRec = (state, action) => {
		let objState = cloneDeep(state),
			{ use_temp_app_rec, unset_creating_additional_app } = action,
			{ app_id, apps } = objState;

		objState.use_temp_app_rec = use_temp_app_rec;

		if (unset_creating_additional_app) {
			objState.creating_additional_app = false;

			if (!use_temp_app_rec && app_id) objState.temp_app_rec = cloneDeep(apps[app_id]);
		}

		return objState;
	},
	createApp = (state, action) => {
		let objState = cloneDeep(state),
			{ cloned_from_id, term_bypassed, essay_requirement } = action;

		objState.app_id = '';
		objState.app_is_saving = true;
		objState.temp_app_rec.json_obj.term_bypassed = term_bypassed || '';

		[
			'initialInformationCompleted',
			'selectCampusCompleted',
			'selectSemesterCompleted',
			'selectAreaStudyCompleted',
			'summaryCompleted'
		].forEach(prop => {
			objState.temp_app_rec.json_obj.screensCompleted[prop] = true;
		});

		objState.temp_app_rec.json_obj.essay.requirement = essay_requirement || 'required';

		if (!!objState.referrer._str || Object.keys(objState.referrer).length > 1) {
			let _referrer = cloneDeep(objState.referrer);
			_referrer._created = true;

			objState.temp_app_rec.json_obj.referrers = [_referrer];
		}

		if (cloned_from_id) {
			cloneApp(
				objState.temp_app_rec.json_obj,
				objState.apps[cloned_from_id].json_obj,
				objState.creating_additional_app,
				essay_requirement
			);
		}

		return objState;
	},
	updateApp = (state, action) => {
		let objState = cloneDeep(state),
			{ obj } = action,
			{ app_id, campus_admin } = objState,
			_app_id = action.app_id || app_id,
			changed_fields = [];

		if (campus_admin) return objState;

		if (objState.use_temp_app_rec) {
			let { json_obj } = objState.temp_app_rec;

			Object.keys(obj).forEach(key => {
				if (typeof obj[key] !== 'undefined' && !_.isEqual(obj[key], json_obj[key])) {
					json_obj[key] = cloneDeep(obj[key]);
					changed_fields.push(key);
				}
			});

			if (check_missing_data(json_obj)) changed_fields.push('screensCompleted');

			if (changed_fields.length) {
				if (changed_fields.includes('application_modifier')) {
					json_obj.residency = calculateResidencyStatus(json_obj);
				}

				json_obj.percentage_complete = calculateProgressbar(json_obj);

				objState.temp_app_rec.json_obj = json_obj;
				objState.temp_app_rec.percentage_complete = json_obj.percentage_complete.toString();
				objState.temp_app_rec.date_last_updated = strNow();
			}
		} else if (_app_id) {
			let { json_obj } = objState.apps[_app_id];

			if (!json_obj.date_submitted) {
				Object.keys(obj).forEach(key => {
					if (typeof obj[key] !== 'undefined' && !_.isEqual(obj[key], json_obj[key])) {
						json_obj[key] = cloneDeep(obj[key]);
						changed_fields.push(key);
					}
				});

				if (check_missing_data(json_obj)) changed_fields.push('screensCompleted');

				if (changed_fields.length) {
					const changed_a_res_field = changed_fields.some(changed_field =>
						[
							'residency',
							'initial_information',
							'application_modifier',
							'academic_background',
							'personal_information'
						].includes(changed_field)
					);

					if (changed_a_res_field) json_obj.residency = calculateResidencyStatus(json_obj);

					if (changed_fields.includes('application_modifier')) {
						json_obj.personal_information.waive_fee = '';
						json_obj.personal_information.waive_fee_reason = '';
						json_obj.personal_information.waive_fee_accepted_warning = '';
						json_obj.screensCompleted.personalInformationAdditionalCompleted = false;
					}

					json_obj.percentage_complete = calculateProgressbar(json_obj);

					objState.apps[_app_id].json_obj = json_obj;
					objState.apps[_app_id].date_last_updated = strNow();
					objState.apps[_app_id].percentage_complete = json_obj.percentage_complete.toString();
					objState.apps[_app_id].app_type = json_obj.application_modifier;
					objState.apps[_app_id].campus = json_obj.initial_information.chosen_campus.title;
					objState.apps[_app_id].term = json_obj.initial_information.chosen_semester.title || '';

					if (!action.app_id) {
						objState.temp_app_rec = cloneDeep(objState.apps[app_id]);

						// re-sort so the updated app is first
						let sorted_apps = { [app_id]: objState.apps[app_id] };
						Object.keys(objState.apps).forEach(key => {
							if (key !== app_id) sorted_apps[key] = objState.apps[key];
						});

						objState.apps = sorted_apps;
					}
				}
			}
		}

		return objState;
	},
	saveApp = (state, action) => {
		let objState = cloneDeep(state),
			{ obj } = action,
			{ app_id, campus_admin } = objState;

		if (campus_admin) return objState;

		if (app_id) {
			const { json_obj } = objState.apps[app_id];

			updateScreensCompleted(objState, obj);

			if (!!json_obj.application_modifier && !_.isEqual(json_obj, app_from_db)) {
				app_from_db = json_obj;
				objState.app_is_saving = true;
			}
		}

		return objState;
	},
	handleAppSave = (state, action) => {
		let objState = cloneDeep(state),
			{ new_app_rec } = action;

		objState.app_is_saving = false;

		if (new_app_rec) {
			try {
				new_app_rec.json_obj = JSON.parse(new_app_rec.application_json);
			} catch (ex) {
				SENTRY.set_tag('triggering_app_id', new_app_rec?.application_id || '');
				throw new Error(`error saving invalid JSON: ${ex.message}`);
			}

			delete new_app_rec.application_json;
			if (new_app_rec.date_submitted === '0000-00-00 00:00:00') new_app_rec.date_submitted = '';

			objState.app_id = new_app_rec.application_id;

			let arr_cart = objState.apps_in_cart ? objState.apps_in_cart.split('|') : [];
			if (arr_cart.indexOf(objState.app_id) < 0) {
				arr_cart.push(objState.app_id);
				objState.apps_in_cart = arr_cart.join('|');
			}

			//collect platform info for support
			new_app_rec.json_obj.initial_information.platform_os = OS();
			new_app_rec.json_obj.initial_information.platform_browser = currentBrowser();
			new_app_rec.json_obj.initial_information.user_agent = userAgent();

			// re-sort so the newly-created app is first
			let sorted_apps = { [new_app_rec.application_id]: new_app_rec };
			Object.keys(objState.apps).forEach(key => {
				if (key !== new_app_rec.application_id) sorted_apps[key] = objState.apps[key];
			});
			objState.apps = sorted_apps;
		}

		return objState;
	},
	deleteApp = (state, action) => {
		let objState = cloneDeep(state),
			{ id } = action;

		if (objState.campus_admin) return objState;

		delete objState.apps[id];

		return objState;
	},
	setSubmenus = (state, action) => {
		let objState = cloneDeep(state),
			{ str, val } = action,
			{ app_id } = objState;

		if (objState.use_temp_app_rec) {
			objState.temp_app_rec.json_obj.submenus[str] = val;
		} else if (app_id) {
			objState.apps[app_id].json_obj.submenus[str] = val;
		}

		return objState;
	},
	setScreensCompleted = (state, action) => {
		let objState = cloneDeep(state),
			{ obj } = action,
			{ use_temp_app_rec, app_id, campus_admin } = objState;

		if (campus_admin) return objState;

		if (use_temp_app_rec || app_id) updateScreensCompleted(objState, obj);

		return objState;
	},
	applyBypassObject = (state, action) => {
		let objState = cloneDeep(state),
			{ obj } = action;

		objState.obj_bypass = obj || DEFAULT.BYPASS_OBJECT;

		return objState;
	},
	createAdditionalApp = (state, action) => {
		let objState = cloneDeep(state),
			{ apps, app_id, campus_admin } = objState;

		if (campus_admin) return objState;

		objState.creating_additional_app = true;

		objState.temp_app_rec = cloneDeep(DEFAULT.APP_REC);
		objState.temp_app_rec.json_obj = cloneDeep(apps[app_id].json_obj);

		objState.temp_app_rec.json_obj.parent_application_id = app_id;
		objState.temp_app_rec.json_obj.initial_information.chosen_campus = {};
		objState.temp_app_rec.json_obj.area_of_study = cloneDeep(DEFAULT.APP_JSON.area_of_study);
		objState.temp_app_rec.json_obj.essay = cloneDeep(DEFAULT.APP_JSON.essay);
		objState.temp_app_rec.json_obj.submit = cloneDeep(DEFAULT.APP_JSON.submit);

		[
			'selectCampusCompleted',
			'selectAreaStudyCompleted',
			'summaryCompleted',
			'essayCompleted',
			'submit',
			'additional',
			'sign',
			'waiver',
			'payment',
			'nextSteps'
		].forEach(str => {
			objState.temp_app_rec.json_obj.screensCompleted[str] = false;
		});

		return objState;
	},
	addToCart = (state, action) => {
		let objState = cloneDeep(state),
			{ id } = action,
			arr = objState.apps_in_cart ? objState.apps_in_cart.split('|') : [];

		if (objState.campus_admin) return objState;

		if (arr.indexOf(id) < 0) {
			arr.push(id);
			objState.apps_in_cart = arr.join('|');

			if (objState.apps_to_submit_later.indexOf(id) > -1) {
				arr = objState.apps_to_submit_later.split('|');
				arr.splice(arr.indexOf(id), 1);
				objState.apps_to_submit_later = arr.join('|');
			}
		}

		objState.apps_in_cart = objState.apps_in_cart.split('|').join('|');

		return objState;
	},
	submitLater = (state, action) => {
		let objState = cloneDeep(state),
			{ id } = action,
			arr = objState.apps_to_submit_later ? objState.apps_to_submit_later.split('|') : [];

		if (objState.campus_admin) return objState;

		if (arr.indexOf(id) < 0) {
			arr.push(id);
			objState.apps_to_submit_later = arr.join('|');

			if (objState.apps_in_cart.indexOf(id) > -1) {
				arr = objState.apps_in_cart.split('|');
				arr.splice(arr.indexOf(id), 1);
				objState.apps_in_cart = arr.join('|');
			}
		}

		return objState;
	},
	setForcePwdReset = (state, action) => {
		let objState = cloneDeep(state);
		objState.force_pwd_reset = action.val;

		return objState;
	},
	setAppFees = (state, action) => {
		let objState = cloneDeep(state),
			{ obj } = action;

		Object.keys(obj).forEach(_app_id => {
			if (objState.apps[_app_id]) objState.apps[_app_id].application_fee = obj[_app_id];
		});

		return objState;
	},
	getDocuments = (state, action) => {
		let objState = cloneDeep(state);
		objState.documents = action.arr;

		return objState;
	},
	addDocument = (state, action) => {
		let objState = cloneDeep(state),
			{ file_type, file_name, key } = action;

		objState.documents.push({
			application_id: objState.app_id,
			user_id: objState.user_id,
			file_name: file_name,
			document_type: file_type,
			key: key
		});

		return objState;
	},
	redeemCoupon = (state, action) => {
		let objState = cloneDeep(state),
			{ app_id, coupon_id } = action;

		objState.apps[app_id].coupon_id = coupon_id;
		objState.apps[app_id].application_fee = 0;

		return objState;
	},
	clearCoupon = (state, action) => {
		let objState = cloneDeep(state),
			{ app_id, app_fee } = action;

		objState.apps[app_id].coupon_id = 0;
		objState.apps[app_id].application_fee = app_fee;

		return objState;
	},
	setDirectAdmit = (state, action) => {
		let objState = cloneDeep(state),
			{ val } = action;

		objState.direct_admit = val;

		return objState;
	},
	setIpAddress = (state, action) => {
		let objState = cloneDeep(state);
		objState.ip_addr = action.obj;

		return objState;
	};

export default createReducer(DEFAULT_STATE.user, {
	[Types.FINISHED_LOADING]: finishedLoading,
	[Types.LOGIN]: login,
	[Types.LOGOUT]: logout,
	[Types.SET_USER_PROPERTY]: setUserProperty,
	[Types.SET_APPS]: setApps,
	[Types.SET_EMAIL_AS_VERIFIED]: setEmailAsVerified,
	[Types.SET_APP_ID]: setAppId,
	[Types.SET_USE_TEMP_APP_REC]: setUseTempAppRec,
	[Types.CREATE_APP]: createApp,
	[Types.UPDATE_APP]: updateApp,
	[Types.SAVE_APP]: saveApp,
	[Types.HANDLE_APP_SAVE]: handleAppSave,
	[Types.DELETE_APP]: deleteApp,
	[Types.SET_SUBMENUS]: setSubmenus,
	[Types.SET_SCREENS_COMPLETED]: setScreensCompleted,
	[Types.APPLY_BYPASS_OBJECT]: applyBypassObject,
	[Types.CREATE_ADDITIONAL_APP]: createAdditionalApp,
	[Types.ADD_TO_CART]: addToCart,
	[Types.SUBMIT_LATER]: submitLater,
	[Types.SET_FORCE_PWD_RESET]: setForcePwdReset,
	[Types.SET_APP_FEES]: setAppFees,
	[Types.GET_DOCUMENTS]: getDocuments,
	[Types.ADD_DOCUMENT]: addDocument,
	[Types.REDEEM_COUPON]: redeemCoupon,
	[Types.CLEAR_COUPON]: clearCoupon,
	[Types.SET_DIRECT_ADMIT]: setDirectAdmit,
	[Types.SET_IP_ADDRESS]: setIpAddress
});

export const UserActions = Creators;
