import { cloneDeep } from 'components/modules/_misc';
import { DEFAULT } from 'components/modules/app';
import { setTimezoneDiff } from 'components/modules/date';
import routes from 'components/routes';

let dt_siteLoad,
	obj_referrer = { _str: document.referrer || '' },
	CryptoJS = require('crypto-js'),
	{ REACT_APP_LOCAL_STORE_UNSAFE_KEY: _key } = process.env;

new URLSearchParams(window.location.search).forEach((value, key) => {
	if (!['load_app_id', 'bypass_term'].includes(key)) obj_referrer[key] = value; // tms:  this is to capture external query params; don't log those that come from us
});

export const DEFAULT_STATE = {
		global: {
			timezone_diff: 99,
			current_page: '',
			prev_page: '',
			err: null,
			loading_data: [],
			date_cached: null,
			system_notifications: [],
			paper_application_url: '',
			campuses: [],
			campus_exceptions: [],
			specials: [],
			career_clusters: [],
			terms: {
				special: [],
				grad: [],
				default_undergrad: [],
				campus_undergrad: []
			},
			cdrMajors: [],
			visa_types: [],
			activities: [],
			waivers: [],
			essay_prompts: {
				global: [],
				campus_specific: []
			},
			campus_next_steps: []
		},
		user: {
			date_cached: null,
			loading_local_user: false,
			referrer: obj_referrer,
			user_id: '',
			email: '',
			first_name: '',
			last_name: '',
			phone_number: '',
			no_phone: false,
			permission_to_send_texts: '',
			email_verified: '',
			verification_code_generated_date: '',
			verification_code_generated: false,
			campus_admin: undefined,
			apps: {},
			app_id: '',
			temp_app_rec: DEFAULT.APP_REC,
			use_temp_app_rec: false,
			creating_additional_app: false,
			documents: [],
			support_student_id: '',
			app_is_saving: false,
			apps_in_cart: '',
			apps_to_submit_later: '',
			obj_bypass: DEFAULT.BYPASS_OBJECT,
			force_pwd_reset: false,
			direct_admit: {},
			ip_addr: {}
		}
	},
	SENSITIVE_USER_FIELDS = ['email', 'phone_number', 'first_name', 'last_name', 'user_id', 'app_id'],
	encrypt = str => {
		return CryptoJS.AES.encrypt(str, _key).toString();
	},
	decrypt = str => {
		return CryptoJS.AES.decrypt(str, _key).toString(CryptoJS.enc.Utf8);
	},
	get_store = () => {
		let support_mode = window.location.href.includes('load_app_id='),
			state = {
				global: localStorage.getItem('global'),
				user: localStorage.getItem('user')
			};

		dt_siteLoad = new Date();

		if (state.global) {
			try {
				state.global = JSON.parse(state.global);

				const d = new Date(state.global.date_cached),
					date_string = str => `${str.getMonth()}-${str.getDate()}-${str.getFullYear()}`;

				if (date_string(d) !== date_string(dt_siteLoad)) {
					localStorage.removeItem('global');
					state.global = DEFAULT_STATE.global;
				} else {
					setTimezoneDiff(state.global.timezone_diff);
				}
			} catch (ex) {
				console.log('could not parse global from localStorage');
				state.global = DEFAULT_STATE.global;
			}
		} else {
			state.global = DEFAULT_STATE.global;
		}

		if (state.user && !support_mode) {
			const user_lifespan = 20 * 60 * 1000; // 20 minutes

			try {
				state.user = JSON.parse(state.user);

				if (dt_siteLoad - new Date(state.user.date_cached) < user_lifespan) {
					SENSITIVE_USER_FIELDS.filter(field => !!state.user[field].length).forEach(field => {
						state.user[field] = decrypt(state.user[field]);
					});
				} else {
					localStorage.removeItem('user');
					state.user = DEFAULT_STATE.user;
				}
			} catch (ex) {
				console.log('could not parse user from localStorage');
				state.user = DEFAULT_STATE.user;
			}
		} else {
			state.user = DEFAULT_STATE.user;
		}

		state.user.referrer = DEFAULT_STATE.user.referrer;

		return state;
	},
	save_store = store => {
		let obj = cloneDeep(store);

		// global data is good for the rest of the day and no longer, no matter what the applicant does
		if (!obj.global.date_cached) obj.global.date_cached = dt_siteLoad;
		obj.global.prev_page = '';
		obj.global.system_notifications = []; // get new notifications on each page load
		obj.global.err = null;

		try {
			localStorage.setItem('global', JSON.stringify(obj.global));
		} catch (ex) {
			localStorage.removeItem('global'); // not enough memory, log user out to prevent loading old data
		}

		if (obj.user.email && !obj.user.support_student_id) {
			// user data has a rolling cache policy; if the applicant refreshes every few minutes, it should never expire;
			obj.user.date_cached = dt_siteLoad;

			obj.user.loading_local_user = true;
			obj.user.apps = {};
			obj.user.temp_app_rec = DEFAULT.APP_REC;
			obj.user.documents = [];

			SENSITIVE_USER_FIELDS.filter(field => !!obj.user[field].length).forEach(field => {
				obj.user[field] = encrypt(obj.user[field]);
			});

			try {
				localStorage.setItem('user', JSON.stringify(obj.user));
			} catch (ex) {
				localStorage.removeItem('user'); // not enough memory, log user out to prevent loading old session data
			}
		}
	},
	check_loading_fields = objState => {
		const route = routes.find(r => r.path === objState.current_page);

		if (!route) return [];

		return (route.dataDependencies || []).concat(route.preloadedData || []).filter(key => {
			const val = objState[key];

			if (typeof val === 'number') return val === 99;

			if (Array.isArray(val) || typeof val === 'string') return !val.length;

			if (typeof val === 'object' && !!val) return !Object.keys(val).every(sub_key => !!val[sub_key].length);

			return false;
		});
	};
