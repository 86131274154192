import React, { Component } from 'react';
import withRouter from 'components/Wrappers/withRouter';
import { connect } from 'react-redux';
import { Row } from 'react-bootstrap';
import { Card } from 'components/Card/Card';
import Button from 'components/CustomButton';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

import PhoneControl from 'components/controls/phone';
import RadioList from 'components/controls/radio_list';
import Select from 'components/controls/select';
import Textbox from 'components/controls/textbox';
import ZipInput from 'components/controls/zip';
import { countries, states } from 'components/modules/opts';
import { SENTRY, cloneDeep, scrollTo, addrIsPOBox } from 'components/modules/_misc';
import { parseAddressFromGoogleResponse } from 'components/modules/address';
import { nextAppPage, prevAppPage } from 'components/modules/nav';
import { GlobalActions } from 'reducers/global';
import { UserActions } from 'reducers/user';
import API from 'components/api';

const addr_fields = [
	'address_1',
	'address_2',
	'city',
	'country',
	'county',
	'state',
	'zip',
	'other_country',
	'other_state',
	'formatted_address'
];

class ContactInformation extends Component {
	constructor(props) {
		super(props);

		const { app, phone_number, no_phone } = props,
			{ permanent_address, other_phone_number } = app.contact_information,
			{
				address_1,
				address_2,
				city,
				country,
				county,
				state,
				zip,
				other_country,
				other_state,
				formatted_address,
				formatted_address_correct
			} = permanent_address;

		this.state = {
			showing_mailing_addr: false,
			validating_addr: false,
			validation_error: false,
			phone_status: other_phone_number?.length > 5 ? 'valid' : 'empty',
			opts_address: [],
			address: {
				address_1: address_1 || '',
				address_2: address_2 || '',
				city: city || '',
				country: country || '',
				county: county || '',
				state: state || '',
				zip: zip || '',
				other_country: other_country || '',
				other_state: other_state || '',
				formatted_address: formatted_address || '',
				formatted_address_correct: formatted_address_correct || ''
			},
			validated_addr: {}
		};

		this.second_phone_required = !(phone_number?.length >= 7) && !no_phone;
	}

	componentDidMount() {
		const { app } = this.props,
			{ mailing_address } = app.contact_information;

		this.orig_mailing_address = mailing_address.formatted_address || '';
	}

	componentDidUpdate(prevProps, prevState) {
		const { updateApp, app } = this.props,
			{ showing_mailing_addr, address } = this.state;

		if (showing_mailing_addr !== prevState.showing_mailing_addr) {
			let contact_information = cloneDeep(app.contact_information);
			contact_information[showing_mailing_addr ? 'permanent_address' : 'mailing_address'] = address;
			updateApp({ contact_information: contact_information });

			const addr = contact_information[showing_mailing_addr ? 'mailing_address' : 'permanent_address'];
			this.setState({
				address: {
					address_1: addr.address_1 || '',
					address_2: addr.address_2 || '',
					city: addr.city || '',
					country: addr.country || '',
					county: addr.county || '',
					state: addr.state || '',
					zip: addr.zip || '',
					other_country: addr.other_country || '',
					other_state: addr.other_state || '',
					formatted_address: addr.formatted_address || '',
					formatted_address_correct: addr.formatted_address_correct || ''
				}
			});
		}
	}

	componentWillUnmount() {
		const { saveApp, app, updateApp, phone_number } = this.props,
			{ address, showing_mailing_addr, phone_status } = this.state,
			contact_information = cloneDeep(app.contact_information),
			_valid = this.isValidForm();

		contact_information[showing_mailing_addr ? 'mailing_address' : 'permanent_address'] = address;

		if (phone_status !== 'valid') {
			contact_information.other_phone_number = ''; // tms: Since the phone input control can't validate on load/render, just don't let an invalid value be saved
			contact_information.other_phone_type = '';
			contact_information.preferred_phone_number = phone_number;
		}

		['permanent_address', 'mailing_address'].forEach(str => {
			if (contact_information[str].country !== 'USA') contact_information[str].state = '';
			if (contact_information[str].country !== 'Other') contact_information[str].other_country = '';
		});

		updateApp({ contact_information: contact_information });

		saveApp(_valid);
	}

	getAPI = () => {
		const { support_student_id, campus_admin } = this.props;
		return new API('https://maps.googleapis.com/maps/api/geocode/', support_student_id, campus_admin);
	};

	isValidAddress = addr => {
		let { address } = this.state,
			{ address_1, country, state, other_country, city, zip } = addr || address;

		return (
			!!address_1 &&
			!!country &&
			!(country === 'USA' && !state) &&
			!(country === 'Other' && !other_country) &&
			!!city &&
			!(['USA', 'CAN'].includes(country) && !zip)
		);
	};

	isValidForm = () => {
		let { app } = this.props,
			{ other_phone_type, preferred_phone_number, receive_mail_at_permanent_address } = app.contact_information,
			{ showing_mailing_addr, phone_status, address } = this.state,
			{ formatted_address, formatted_address_correct, country } = address,
			valid = this.isValidAddress() && !(country === 'USA' && !!formatted_address && !formatted_address_correct);

		if (!showing_mailing_addr) {
			valid =
				valid &&
				!(this.second_phone_required && phone_status !== 'valid') &&
				!(phone_status !== 'empty' && !other_phone_type) &&
				!(phone_status !== 'empty' && !preferred_phone_number) &&
				!(!!formatted_address && !receive_mail_at_permanent_address);
		}

		return valid;
	};

	formatAddressInputs = addr => {
		const { address_1, address_2, city, state, zip, country, other_country } = addr;

		let address_lines = `${address_1}`;
		if (address_2) address_lines += `, ${address_2}`;

		return [address_lines, city, state, zip, country === 'Other' ? other_country : country].join(', ');
	};

	onUseInputtedAddress = e => {
		e.preventDefault();
		e.stopPropagation();

		let addr = this.sent_addr;
		addr.formatted_address = this.formatAddressInputs(addr);
		addr.formatted_address_correct = 'Yes';

		this.setState({ validation_error: false, address: addr });
	};

	onValidateAddress = () => {
		let { captureError } = this.props,
			{ address } = this.state;

		let addressFields = [
			address.address_1,
			address.address_2,
			address.city,
			address.state,
			address.other_state,
			address.county,
			address.zip,
			address.country
		];

		if (address.country === 'Other') addressFields.push(address.other_country);

		let strAddress = addressFields.filter(str => !!str).join(' ');

		this.setState({ validating_addr: true });

		this.sent_addr = { ...address };

		this.getAPI()
			.getAllValidAddresses(strAddress)
			.then(resp => {
				if (typeof resp === 'string' && resp.includes('error')) {
					this.setState({ validation_error: true });
				} else {
					const opts = resp.results.filter(rec => rec.address_components.length > 5);

					if (!opts.length) {
						this.setState({ validation_error: true });
					} else if (opts.length === 1) {
						const addr = parseAddressFromGoogleResponse(opts[0], address);
						this.setState({ address: addr, validated_addr: addr });
					} else {
						this.setState({ opts_address: opts });
					}
				}
			})
			.catch(ex => {
				SENTRY.set_tag('address_string', strAddress);
				captureError(new Error(`error valdating address: ${ex.message}`));
			})
			.finally(() => this.setState({ validating_addr: false }));
	};

	onSubmit = e => {
		e.preventDefault();
		e.stopPropagation();

		let { app, updateApp, navigate, location } = this.props,
			{ showing_mailing_addr } = this.state,
			contact_information = cloneDeep(app.contact_information),
			{ permanent_address, mailing_address, receive_mail_at_permanent_address } = contact_information,
			address = cloneDeep(this.state.address),
			{ country } = showing_mailing_addr ? mailing_address : permanent_address;

		if (!address.formatted_address) {
			address.formatted_address_correct = '';

			contact_information[showing_mailing_addr ? 'mailing_address' : 'permanent_address'] = address;
			updateApp({ contact_information: contact_information });

			this.onValidateAddress();
		} else {
			if (country !== 'USA') {
				address.formatted_address = 'NONE, INTERNATIONAL';
				address.formatted_address_correct = '';

				contact_information[showing_mailing_addr ? 'mailing_address' : 'permanent_address'] = address;
				updateApp({ contact_information: contact_information });
			}

			if (showing_mailing_addr || receive_mail_at_permanent_address === 'Yes') {
				navigate(nextAppPage(app, location.pathname));
			} else {
				this.setState({ showing_mailing_addr: true });
			}
		}
	};

	onBack = e => {
		e.preventDefault();
		e.stopPropagation();

		let { app, navigate, location } = this.props,
			{ showing_mailing_addr } = this.state;

		if (showing_mailing_addr) {
			this.setState({ showing_mailing_addr: false });
			scrollTo();
		} else {
			navigate(prevAppPage(app, location.pathname));
		}
	};

	checkParentAddr(contact_information) {
		let { updateApp, app } = this.props,
			{ showing_mailing_addr } = this.state,
			parent_info = cloneDeep(app.parent_info);

		(parent_info.parents || []).forEach(parent => {
			if (parent.same_current_address) {
				const whichAddress =
					contact_information.receive_mail_at_permanent_address === 'No' &&
					this.orig_mailing_address &&
					parent.which_address === this.orig_mailing_address
						? 'mailing_address'
						: 'permanent_address';

				if (showing_mailing_addr === (parent.which_address === 'mailing_address')) {
					addr_fields.forEach(prop => {
						parent.current_address[prop] = app.contact_information[whichAddress][prop];
					});
				}
			}
		});

		updateApp({ parent_info: parent_info });
	}

	onChange = (prop, val, phone_status) => {
		const { app, updateApp } = this.props,
			contact_information = cloneDeep(app.contact_information);

		if (prop === 'other_phone_number') {
			this.setState({ phone_status: phone_status });

			if (contact_information.preferred_phone_number === contact_information[prop]) {
				contact_information.preferred_phone_number = '';
			}
		}

		contact_information[prop] = val;
		this.checkParentAddr(contact_information);
		updateApp({ contact_information: contact_information });
	};

	onChangeAddress = (prop, val) => {
		let { app } = this.props,
			{ address, showing_mailing_addr, validated_addr } = this.state,
			addr = { ...address, [prop]: val };

		if (prop === 'zip' && addr.country === 'CAN') val = val.toUpperCase();

		if (!showing_mailing_addr && !!addrIsPOBox(addr)) {
			val = '';

			alert(
				"This area is for physical addresses only.  You'll have the opportunity to provide a separate mailing address."
			);
		}

		addr[prop] = val;

		if (addr_fields.includes(prop)) {
			addr.formatted_address =
				addr.country !== 'USA' || addr.formatted_address_correct === 'No' ? this.formatAddressInputs(addr) : '';
			addr.formatted_address_correct = '';
			this.setState({ validation_error: false });
		}

		if (prop === 'formatted_address_correct' && val === 'No') {
			addr = app.application_modifier === 'DA' ? address : this.sent_addr;
			addr.formatted_address = this.formatAddressInputs(addr);
			addr.formatted_address_correct = 'No';
		}

		if (prop === 'formatted_address_correct' && val === 'Yes' && validated_addr?.formatted_address) {
			addr.formatted_address = validated_addr.formatted_address;
		}

		this.setState({ address: addr });
	};

	renderAddress = () => {
		let { opts_address, address, validation_error } = this.state,
			{
				country,
				state,
				other_country,
				address_1,
				address_2,
				city,
				zip,
				other_state,
				formatted_address,
				formatted_address_correct
			} = address;

		return (
			<>
				<Row>
					<Textbox
						name='address_1'
						value={address_1}
						label='Address'
						placeholder='Street name and number'
						required={true}
						cols={12}
						maxLength={55}
						onChange={val => this.onChangeAddress('address_1', val)}
					/>
				</Row>
				<Row>
					<Textbox
						name='address_2'
						value={address_2}
						label='Address Line 2'
						placeholder='Apartment, building, or unit #'
						cols={12}
						maxLength={55}
						onChange={val => this.onChangeAddress('address_2', val)}
					/>
				</Row>
				<Row>
					<Select
						name='country'
						value={country}
						label='Country'
						placeholder='Select Country'
						opts={countries}
						required={true}
						cols={6}
						onChange={val => this.onChangeAddress('country', val)}
					/>

					{country === 'USA' && (
						<Select
							name='state'
							value={state}
							label='State'
							placeholder='Select State'
							opts={states}
							required={true}
							cols={6}
							onChange={val => this.onChangeAddress('state', val)}
						/>
					)}

					{country === 'Other' && (
						<Textbox
							name='other_country'
							value={other_country}
							label='Specify Country'
							placeholder='Other Country'
							required={true}
							cols={6}
							maxLength={40}
							onChange={val => this.onChangeAddress('other_country', val)}
						/>
					)}
				</Row>
				<Row>
					<Textbox
						name='city'
						value={city}
						label='City'
						placeholder='City'
						required={true}
						cols={6}
						maxLength={40}
						onChange={val => this.onChangeAddress('city', val)}
					/>
				</Row>
				<Row>
					<ZipInput
						name='zip'
						value={zip}
						label='Zip / Postal Code'
						placeholder='Zip / Postal Code'
						country={country}
						required={['USA', 'CAN'].includes(country)}
						cols={6}
						onChange={val => this.onChangeAddress('zip', val)}
					/>
					<Textbox
						name='other_state'
						value={other_state}
						label='Other State/Province'
						placeholder='Other State'
						cols={6}
						maxLength={40}
						onChange={val => this.onChangeAddress('other_state', val)}
					/>
				</Row>

				{validation_error && (
					<Row className='addr-validation-err'>
						<h3 className='uwred'>
							Your address isn&apos;t being confirmed, please double check for accuracy or confirm that
							the address you provided is correct.
						</h3>
						<Button bsStyle='info' className='back-btn' fill onClick={this.onUseInputtedAddress}>
							The address entered is correct
						</Button>
					</Row>
				)}

				{country === 'USA' && !!formatted_address && (
					<>
						<Row>
							<Textbox
								name='formatted_address'
								value={formatted_address}
								label='Formatted Address'
								cols={12}
							/>
						</Row>
						<Row>
							<RadioList
								name='formatted_address_correct'
								value={formatted_address_correct}
								label='Is the formatted address above correct?'
								opts={['Yes', 'No']}
								onChange={val => this.onChangeAddress('formatted_address_correct', val)}
								required={true}
								cols={12}
							/>
						</Row>

						{formatted_address_correct === 'No' && (
							<p>
								<strong>Thank you, we used your address input instead of Google&apos;s.</strong>
							</p>
						)}
					</>
				)}

				{!!opts_address.length && (
					<div className='col-md-12 chooseAddressContainer'>
						<h3 className='uwred text-center'>Please select the correct address:</h3>

						{opts_address.map(addr_resp => (
							<div className='text-center' key={addr_resp.place_id}>
								<a
									aria-label='Select a valid address'
									className='chooseAddress'
									onClick={() => {
										const addr = parseAddressFromGoogleResponse(addr_resp, address);
										this.setState({ opts_address: [], address: addr, validated_addr: addr });
									}}>
									{addr_resp.formatted_address}
								</a>
							</div>
						))}
					</div>
				)}

				<div className='clearfix' />
			</>
		);
	};

	renderPage = () => {
		const { app, email, phone_number } = this.props,
			{ showing_mailing_addr, address, phone_status } = this.state,
			{ other_phone_type, other_phone_number, preferred_phone_number, receive_mail_at_permanent_address } =
				app.contact_information;

		return showing_mailing_addr ? (
			<>
				<h3 className='fontNormal'>Mailing Address</h3>
				<a id='mailAddressTooltip'>
					What is this address used for?{' '}
					<img className='informationTooltip' src={require('assets/img/Information.png')} alt='Information' />
				</a>
				<Tooltip
					anchorId='mailAddressTooltip'
					className='tooltipContainer'
					delayHide={1000}
					effect='solid'
					content={<p>Campuses may send mail to this address.</p>}
				/>

				{this.renderAddress()}
			</>
		) : (
			<>
				<Row>
					<Textbox name='email' value={email} label='Email Address' cols={phone_number ? 6 : 12} />
					{!!phone_number && (
						<PhoneControl
							name='phone_number'
							value={phone_number}
							label='Cell/Mobile Number'
							cols={6}
							disabled={true}
							tooltip={
								<a id='phoneTooltip'>
									<img
										className='informationTooltip'
										src={require('assets/img/Information.png')}
										alt='Information'
									/>
								</a>
							}
						/>
					)}

					<Tooltip
						anchorId='phoneTooltip'
						className='tooltipContainer'
						delayHide={1000}
						effect='solid'
						content={
							<p>
								You set this phone number when you created your account. It can be changed on the
								account information page.
							</p>
						}
					/>
				</Row>
				<Row>
					<PhoneControl
						name='other_phone_number'
						value={other_phone_number}
						label={`${phone_number ? 'Other ' : ''}Phone Number`}
						required={this.second_phone_required || !!other_phone_type}
						cols={8}
						onChange={(val, status) => this.onChange('other_phone_number', val, status)}
					/>

					<Select
						name='other_phone_type'
						value={other_phone_type}
						label='Type'
						placeholder='Select Type'
						opts={['Cell', 'Work', 'Home']}
						required={this.second_phone_required || phone_status !== 'empty'}
						cols={4}
						onChange={val => this.onChange('other_phone_type', val)}
					/>
				</Row>

				{phone_status === 'valid' && (
					<Row>
						<Select
							name='preferred_phone_number'
							value={preferred_phone_number}
							label='Preferred Phone Number'
							placeholder='Phone Number'
							opts={[phone_number, other_phone_number]}
							required={true}
							cols={8}
							onChange={val => this.onChange('preferred_phone_number', val)}
						/>
					</Row>
				)}

				<h3>PERMANENT ADDRESS</h3>
				<a id='permanentAddressTooltip'>
					How will you use my address?
					<img className='informationTooltip' src={require('assets/img/Information.png')} alt='Information' />
				</a>
				<Tooltip
					anchorId='permanentAddressTooltip'
					className='tooltipContainer'
					delayHide={1000}
					effect='solid'
					content={
						<p>
							The campus will list this as your permanent address, and it will be used if you are trying
							to qualify for Wisconsin residency. If you do not indicate a separate mailing address after
							validation, this address will also be listed as your mailing address.
						</p>
					}
				/>

				{this.renderAddress()}

				{!!address.formatted_address && (
					<Row>
						<RadioList
							name='receive_mail_at_permanent_address'
							value={receive_mail_at_permanent_address}
							label='Is the address listed above your mailing address? (If no, you will need to supply a mailing address)'
							opts={['Yes', 'No']}
							onChange={val => this.onChange('receive_mail_at_permanent_address', val)}
							required={true}
							cols={12}
						/>
					</Row>
				)}
			</>
		);
	};

	render() {
		let { address, validating_addr } = this.state,
			submit_text = address.formatted_address ? 'Save and Continue' : 'Validate',
			_valid = this.isValidForm();

		if (validating_addr) submit_text = 'Validating';

		return (
			<Card
				title='CONTACT INFORMATION'
				content={
					<form id='contactForm' onSubmit={this.onSubmit}>
						{this.renderPage()}
						<div className='clearfix' />
					</form>
				}
				buttons={
					<>
						<Button bsStyle='info' className='back-btn' fill onClick={this.onBack}>
							Back
						</Button>
						<Button
							form='contactForm'
							bsStyle='info'
							className='save-btn'
							fill
							type='submit'
							disabled={!_valid}>
							{submit_text}
						</Button>
					</>
				}
			/>
		);
	}
}

const mapStateToProps = state => {
		const { app_id, apps, email, phone_number, no_phone, support_student_id, campus_admin } = state.user;

		return {
			app: apps[app_id].json_obj,
			apps: apps,
			app_id: app_id,
			email: email,
			phone_number: phone_number,
			no_phone: no_phone,
			support_student_id: support_student_id,
			campus_admin: campus_admin
		};
	},
	mapDispatchToProps = dispatch => ({
		updateApp: obj => dispatch(UserActions.updateApp(obj)),
		saveApp: val => dispatch(UserActions.saveApp({ contactInfoCompleted: val })),
		captureError: err => dispatch(GlobalActions.captureError(err))
	});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContactInformation));
